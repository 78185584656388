// import { BreadcrumbItem, Breadcrumb } from "reactstrap";
import { Breadcrumb } from "antd";
import CartStep2 from "../../../../assets/images/cart-step2.svg";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import firetoast from "./../../../../Helpers/FireToast";
import Endpoint from "./../../../../Utils/Endpoint";
import BanglaBazarApi from "./../../../Api/BanglaBazarApi";
import { Footer } from "./../Layout/Footer";
import { WebsiteHeader } from "./../Layout/Header/Header";
import { NewsLetter } from "./../Layout/NewsLetter";
import CartDetailCartItem from "./../Layout/My Cart Items/CartDetailCartItem";
import BangladeshDelivery from "./BangladeshDelivery";
import UsaDelivery from "./UsaDelivery";
import GlobalDeliveryDHL from "./GlobalDeliveryDHL";
import GlobalDeliveryXPS from "./GlobalDeliveryXPS";
import GlobalUSAShipping from "./GlobalUSAShipping";
import { HiChevronRight } from "react-icons/hi2";

import { Spinner } from "reactstrap";
import { event } from "jquery";

function PaymentAndDelivery() {
  const location = useLocation();
  const isInitialMount = useRef(true);

  const search = useLocation().search;
  const product_ids = new URLSearchParams(search).get("product");
  const productGlobalShipping = new URLSearchParams(search).get(
    "GlobalShipping"
  );
  const productShippingAvailable = new URLSearchParams(search).get(
    "ShippingAvailable"
  );
  const productCity = new URLSearchParams(search).get("City");
  // console.log(product_ids,productGlobalShipping,productShippingAvailable,productCity)

  const [CartItems, setCartItems] = useState([]);
  const [ProductCombinationItems, setProductCombinationItems] = useState([]);
  const [TotalPrice, setTotalPrice] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const { getCartItem, paymentStatus } = state;
  const [BanglaBazarPickUp, setBanglaBazarPickUp] = useState(null);
  const [PickUpByUser, setPickUpByUser] = useState(null);
  const [AllowStorePickup, setAllowStorePickup] = useState("N");
  const [PaymentStates, setPaymentStates] = useState([]);
  const [PaymentCities, setPaymentCities] = useState([]);
  const [OverallCity, setOverallCity] = useState("");
  const [PaymentType, setPaymentType] = useState("card");
  const [ContinueButton, setContinueButton] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const productIds = queryParams.get("product");
  const [ShippingPrice, setShippingPrice] = useState(0);
  const [ShowPrevPayment, setShowPrevPayment] = useState(true);
  const [DeliveryCountryList, setDeliveryCountryList] = useState([]);
  const [DeliveryCountry, setDeliveryCountry] = useState(null);
  const [DeliveryForm, setDeliveryForm] = useState(null);
  const [DeliveryBy, setDeliveryBy] = useState(null);
  const [TaxValue, setTaxValue] = useState(0);
  const [AllCod, setAllCod] = useState(true);
  const [PaymentMethod, setPaymentMethod] = useState(true);
  const [region, setRegion] = useState("");
  const [loadAgain, setLoadAgain] = useState(false);

  const [subTotal, setSubTotal] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [isDropdownLoaded, setIsDropdownLoaded] = useState(false);

  // useEffect(() => {
  //   // Check if the component was previously loaded
  //   if (!sessionStorage.getItem("componentLoaded")) {
  //     sessionStorage.setItem("componentLoaded", "true");
  //   } else {
  //     // This block will run on subsequent loads, including after a refresh
  //     const currentPath = location.pathname;
  //     const params = new URLSearchParams(location.search);

  //     const hasProduct = params.has('product');
  //     const hasGlobalShipping = params.has('GlobalShipping');
  //     const hasCity = params.has('City');

  //     const targetPath = "/my-cart/delivery-details";

  //     // Check if the path matches and the required parameters are present
  //     if (currentPath === targetPath && hasProduct && hasGlobalShipping && hasCity) {
  //       window.location.href = "/my-cart";
  //     }
  //   }

  //   return () => {
  //     sessionStorage.removeItem("componentLoaded");
  //   };
  // }, [location]);

  // useEffect(() => {
  //   if (localStorage.getItem('region') === 'Bangladesh') {
  //     getTax()
  //     setDeliveryCountry(16);
  //     localStorage.setItem('DSCountry', 'Bangladesh')
  //   }
  //   if (localStorage.getItem('region') == 'United States') {
  //     setDeliveryCountry(226);
  //     localStorage.setItem('DSCountry', 'United States')
  //   }
  //   console.log(localStorage.getItem('DSCountry'));
  // }, [loadAgain])

  useEffect(() => {
    if (localStorage.getItem("region") === "Bangladesh") {
      getTax();

      localStorage.setItem("DSCountry", "Bangladesh");
    }
    if (localStorage.getItem("region") == "United States") {
      localStorage.setItem("DSCountry", "United States");
    }
    console.log(localStorage.getItem("DSCountry"));
  }, [loadAgain]);

  useEffect(() => {
    // Get the selected country from localStorage, fallback to 'country' if 'setSelectedCountry' is not available
    const selectedCountry =
      localStorage.getItem("setSelectedCountry") ||
      localStorage.getItem("country");

    console.log("selectedCountry", selectedCountry);
    const DeliveryCountryId =localStorage.getItem("DCID")
    if(!DeliveryCountryId){
    // Match the country and set DeliveryCountry accordingly
    switch (selectedCountry) {
      case "bangladesh":
        setDeliveryCountry(16);
        localStorage.setItem("DSCountry", "BD");
        break;
      case "pakistan":
        setDeliveryCountry(159);
        localStorage.setItem("DSCountry", "PK");
        break;
      case "south africa":
        setDeliveryCountry(159);
        localStorage.setItem("DSCountry", "ZAR");
        break;
      case "canada":
        setDeliveryCountry(37);
        localStorage.setItem("DSCountry", "CA");
        break;
      case "australia":
        setDeliveryCountry(11);
        localStorage.setItem("DSCountry", "AU");
        break;
      case "united states":
        setDeliveryCountry(226);
        localStorage.setItem("DSCountry", "US");
        break;
      default:
        setDeliveryCountry(226);
        localStorage.setItem("DSCountry", "US");
        break;
    }
  }else{
    setDeliveryCountry(DeliveryCountryId)
  }
    console.log("Delivery Country Set to:", selectedCountry);
  }, [loadAgain]); // Dependency on loadAgain state

  useEffect(() => {
    getCountries();
    setRegion(localStorage.getItem("region"));
    if (!getCartItem.loading) {
      if (getCartItem.error) {
        firetoast(
          "Something went wrong while fetching cart items",
          "default-error"
        );
      } else {
        if (!productIds) {
          var tempIds = [];
          var productCities = [];
          setCartItems(getCartItem.data.productCartList);
          getPaymentClient(CartItems[0]?.ProductCountry);
          setProductCombinationItems(
            getCartItem.data.productCombinationPriceDetail
          );

          console.log(
            "getCartItem.data.productCombinationPriceDetai=================>",
            getCartItem.data.productCombinationPriceDetail
          );

          var totalCount = 0;
          let tempCombination = getCartItem.data.productCombinationPriceDetail;
          let indexes = getCartItem.data.productCartList;

          for (let i = 0; i < indexes.length; i++) {
            tempIds.push(indexes[i].ProductID);
            productCities.push(indexes[i].City);
            console.log(tempIds);
            let currentProduct = getCartItem.data.productCartList[i];
            let currentCombination = tempCombination[i];
            totalCount += parseFloat(currentProduct.Price);

            for (let j = 0; j < currentCombination.length; j++) {
              totalCount += parseFloat(
                currentCombination[j].ProductCombinationPrice
              );
            }

            totalCount = totalCount * parseInt(currentProduct.Total_Quantity);
          }

          console.log("totalCount", totalCount);

          setTotalPrice(totalCount);
          getDeliveryStatus(tempIds);

          if (sameValues(productCities)) {
            setOverallCity(productCities[0]);
          }
        } else {
          var productCities = [];
          var tempIds = [];
          var _indexes = productIds.split(",");
          let _actual = getCartItem.data.productCartList;

          for (let _indexes = 0; _indexes < _actual.length; _indexes++) {
            tempIds.push(_actual[_indexes].ProductID);
          }
          var ActualProductCartList = getCartItem.data.productCartList;
          var ActualProductCombinationItems =
            getCartItem.data.productCombinationPriceDetail;
          var idsToMap = productIds.split(",");
          getDeliveryStatus(tempIds);
          var productCartList = [];
          var productCombinationItems = [];
          for (let i = 0; i < idsToMap.length; i++) {
            for (let j = 0; j < ActualProductCartList.length; j++) {
              if (parseInt(idsToMap[i]) === parseInt(j)) {
                productCities.push(ActualProductCartList[i].City);
                productCartList.push(ActualProductCartList[j]);
                productCombinationItems.push(ActualProductCombinationItems[j]);
              }
            }
          }
          setCartItems(productCartList);

          getPaymentClient(CartItems[0]?.ProductCountry);
          setProductCombinationItems(productCombinationItems);

          var totalCount = 0;
          let tempCombination = productCombinationItems;
          let indexes = productCartList;
          let CodStats = [];
          let prices = [];
          let taxes = [];

          for (let i = 0; i < indexes.length; i++) {
            let currentProduct = productCartList[i];
            let currentCombination = tempCombination[i];
            var amount =
              parseFloat(currentProduct.Price) *
              parseInt(currentProduct.Total_Quantity);
            var taxAmount = amount * (parseFloat(indexes[i]["TaxRate"]) / 100);
            taxAmount = taxAmount ? taxAmount : 0;
            prices.push(amount);
            taxes.push(taxAmount);
            // totalCount = totalCount + parseFloat(currentProduct.Price);
            // for (let j = 0; j < currentCombination.length; j++) {
            //   totalCount += parseFloat(
            //     currentCombination[j].ProductCombinationPrice
            //   );
            // }
            // totalCount = totalCount * parseInt(currentProduct.Total_Quantity);
            // console.log(totalCount,"totalCount")
            // totalTax =
            //   totalTax + (totalCount * (parseFloat(indexes[i]["TaxRate"]) / 100));
            CodStats.push(productCartList[i].ProductCodStatus);
          }

          let sum = prices.reduce((a, b) => a + b, 0);

          let taxesAmt = taxes.reduce((a, b) => a + b, 0);
          setTotalPrice(sum);
          if (sameValues(productCities)) {
            setOverallCity(productCities[0]);
          }

          if (!AllowedCod(CodStats)) {
            setAllCod(false);
          }
        }
      }
    }
  }, [
    getCartItem.loading,
    getCartItem.data,
    paymentStatus.loading,
    productIds,
  ]);

  function sameValues(arr) {
    return arr.every((v, i, a) => v === a[0]);
  }
  function AllowedCod(arr) {
    return arr.every((element) => element === "Y");
  }
  let getDeliveryStatus = async (idArrays) => {
    let temp = [];

    for (let i = 0; i < idArrays.length; i++) {
      temp.push({
        ProductID: idArrays[i],
      });
    }
    try {
      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/payment/shipping-status`,
        {
          ProductDetail: temp,
        }
      );
      setBanglaBazarPickUp(response.data.banglaBazarPickup);
      setPickUpByUser(response.data.pickUpByUser);
    } catch (e) {
      firetoast("Something went wrong", "default-error");
    }
  };

  let handleDeliveryCountry = ({ currentTarget: input }) => {
    //CartItems[0].ProductCountry
    const data = DeliveryCountryList.filter((item) => {
      return input.value == item.CountryID;
    });

    
    localStorage.setItem("persistedCountry", JSON.stringify(data[0]));
    localStorage.setItem("setSelectedCountry", data[0].Country.toLowerCase());

    localStorage.setItem("currency", data[0].ToCurrency);
    localStorage.setItem("iso", data[0].ISO2);

    localStorage.setItem("DSCountry", data[0].ISO2);
    localStorage.setItem("DCID", data[0].CountryID);

    if (data[0].Country === "Bangladesh") {
      localStorage.setItem("region", "Bangladesh");
    } else {
      localStorage.setItem("region", "United States");
    }

    console.log("data", data);

    //new implementation
    setDeliveryCountry(data[0].CountryID);

    setPaymentType("card");
    setDeliveryCountry(input.value);
    var tempIds = [];
    var productCities = [];
    var totalCount = 0;
    let totalTax = 0;

    let tempCombination = getCartItem.data.productCombinationPriceDetail;
    let indexes = getCartItem.data.productCartList;

    for (let i = 0; i < indexes.length; i++) {
      tempIds.push(indexes[i].ProductID);
      productCities.push(indexes[i].City);
      let currentProduct = getCartItem.data.productCartList[i];
      let currentCombination = tempCombination[i];
      let productTotalCount = parseFloat(currentProduct.Price);

      for (let j = 0; j < currentCombination.length; j++) {
        productTotalCount += parseFloat(
          currentCombination[j].ProductCombinationPrice
        );
      }

      productTotalCount *= parseInt(currentProduct.Total_Quantity);
      totalCount += productTotalCount;

      totalTax += productTotalCount * (parseFloat(indexes[i]["TaxRate"]) / 100);
    }

    setTotalPrice(totalCount);
    getDeliveryStatus(tempIds);

    if (parseInt(CartItems[0].ProductCountry) === parseInt(input.value)) {
      setTaxValue(totalTax);
    } else {
      setTaxValue(0);
    }

    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  function getTax() {
    var tempIds = [];
    var productCities = [];
    var totalCount = 0;
    let totalTax = 0;

    let tempCombination = getCartItem.data.productCombinationPriceDetail;
    let indexes = getCartItem.data.productCartList;

    // return

    for (let i = 0; i < indexes.length; i++) {
      tempIds.push(indexes[i].ProductID);
      productCities.push(indexes[i].City);
      let currentProduct = getCartItem.data.productCartList[i];

      const product = currentProduct
      let basePrice = parseFloat(product.Price);

      const currentDate = new Date();

      // Parse PromotionStartDate and PromotionEndDate if they are strings
      const promotionStartDate = new Date(product.PromotionStartDate);
      const promotionEndDate = new Date(product.PromotionEndDate);
      // Check if the current date is within the promotion period
      if (currentDate >= promotionStartDate && currentDate <= promotionEndDate) {
        if (product.PromotionPrice > 0) {
          basePrice = parseFloat(product.PromotionPrice);
        } else if (product.PromotionRate > 0) {
          const discount = parseFloat(product.Price) * (product.PromotionRate / 100);
          basePrice = parseFloat(product.Price) - discount;
        }
      }

      let currentCombination = tempCombination[i];
      let productTotalCount = basePrice;

      for (let j = 0; j < currentCombination.length; j++) {
        productTotalCount += parseFloat(
          currentCombination[j].ProductCombinationPrice
        );
      }

      productTotalCount *= parseInt(currentProduct.Total_Quantity);
      totalCount += productTotalCount;

      totalTax += productTotalCount * (parseFloat(indexes[i]["TaxRate"]));
      // totalTax += productTotalCount * (parseFloat(indexes[i]["TaxRate"]) / 100);
    }

    // return
    setTotalPrice(totalCount);
    getDeliveryStatus(tempIds);
    // console.log(CartItems[0].ProductCountry, "CartItems[0].ProductCountry");
    // console.log(input.value, "input.value");
    setLoadAgain(true);

    if (parseInt(CartItems[0]?.ProductCountry) === 16) {
      console.log("setting tax value in if condition");
      setTaxValue(totalTax);
    } else {
      console.log("setting tax 0 in if condition");

      setTaxValue(0);
    }
  }

  var getPaymentClient = async (id) => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint + `/api/payment/get-paymentGateway/${id}`
      );
      console.log(
        response.data.PaymentGateway.GatewayName,
        "response.data.PaymentGateway.GatewayName"
      );
      setPaymentMethod(response.data.PaymentGateway.GatewayName);
    } catch (e) {
      console.log(e);
    }
  };

  var getCountries = async () => {
    try {
      var response = await BanglaBazarApi.get(
        Endpoint + "/api/location/get-deliveryAllowedCountries"
      );
      let a = 1;
      for (let i = 0; i < getCartItem.data?.productCartList.length; i++) {
        if (getCartItem.data?.productCartList[i]["ShippingGlobal"] === "N") {
          for (let j = 0; j < response.data.Countries.length; j++) {
            if (
              response.data.Countries[j]["CountryID"] ===
              getCartItem.data.productCartList[i]["ProductCountry"]
            ) {
              let arr = [];
              arr.push(response.data.Countries[j]);
              setDeliveryCountryList(arr);
              setIsDataLoaded(true);
              setIsDataLoaded(true);
              a = 2;
            }
          }
        }
      }

      if (a === 1) setDeliveryCountryList(response.data.Countries);
    } catch (e) {
      console.log(e);
    }
  };

  console.log("totalPrice", CartItems);

  const getSubTotal = async () => {
    try {
      let totalPrice = 0;
      CartItems.map((product, i) => {
        var priceArray = ProductCombinationItems[i];

        console.log("priceArray", priceArray);

        // console.log(priceArray);
        for (let i = 0; i < priceArray.length; i++) {
          console.log(totalPrice, priceArray[i]);
          totalPrice =
            totalPrice + parseFloat(priceArray[i]["ProductCombinationPrice"]);
        }
        let basePrice = parseFloat(product.Price);
        if (product.Total_Quantity >= parseFloat(product.Quantity1) && parseFloat(product.PriceQuantity1) > 0) {
          basePrice = parseFloat(product.PriceQuantity1)
        } else if (product.Total_Quantity >= parseFloat(product.Quantity2) && parseFloat(product.PriceQuantity2) > 0) {
          basePrice = parseFloat(product.PriceQuantity2)
        } else if (product.Total_Quantity >= parseFloat(product.Quantity3) && parseFloat(product.PriceQuantity3) > 0) {
          basePrice = parseFloat(product.PriceQuantity3)
        }

        totalPrice =
          (basePrice + totalPrice) *
          parseFloat(product.Total_Quantity);
      });
      const storedValue = localStorage.getItem("totalAmountCartItems");
      const amount = storedValue ? Number(storedValue) : 0;

      setSubTotal(amount);
    } catch (err) { }
  };

  useEffect(() => {
    getSubTotal();
  }, [CartItems]);

  console.log("subTotal", CartItems);

  window.onload = function () {
    var currentPath = window.location.pathname + window.location.search;
    var targetPath = "http://localhost:3000/my-cart/delivery-details";
    var queryParams =
      "?product=0,1&GlobalShipping=Y&ShippingAvailable=Y&City=All";
    console.log("ddd");
    // Check if the current URL matches the specific route
    if (currentPath === targetPath + queryParams) {
      // Redirect to "/my-cart"
      window.location.href = "http://localhost:3000/my-cart";
    }
  };

  const calculateItemTotal = (product, combination) => {
    let totalPrice = combination.reduce((acc, item) => {
      return acc + parseFloat(item["ProductCombinationPrice"]);
    }, 0);

    let basePrice = parseFloat(product.Price);

    const currentDate = new Date();

    // Parse PromotionStartDate and PromotionEndDate if they are strings
    const promotionStartDate = new Date(product.PromotionStartDate);
    const promotionEndDate = new Date(product.PromotionEndDate);
    // Check if the current date is within the promotion period
    if (currentDate >= promotionStartDate && currentDate <= promotionEndDate) {
      if (product.PromotionPrice > 0) {
        basePrice = parseFloat(product.PromotionPrice);
      } else if (product.PromotionRate > 0) {
        const discount = parseFloat(product.Price) * (product.PromotionRate / 100);
        basePrice = parseFloat(product.Price) - discount;
      }
    }

    if (product.Total_Quantity >= parseFloat(product.Quantity1) && parseFloat(product.PriceQuantity1) > 0) {
      basePrice = parseFloat(product.PriceQuantity1)
    } else if (product.Total_Quantity >= parseFloat(product.Quantity2) && parseFloat(product.PriceQuantity2) > 0) {
      basePrice = parseFloat(product.PriceQuantity2)
    } else if (product.Total_Quantity >= parseFloat(product.Quantity3) && parseFloat(product.PriceQuantity3) > 0) {
      basePrice = parseFloat(product.PriceQuantity3)
    }

    return (
      (basePrice + totalPrice) *
      parseFloat(product.Total_Quantity)
    );
  };

  // Function to calculate the total price for all items
  const calculateTotalPrice = () => {
    let total = 0;
    CartItems.forEach((item, index) => {
      total += calculateItemTotal(item, ProductCombinationItems[index]);
    });
    return total;
  };

  console.log("catI", CartItems);

  localStorage.setItem(
    "totalAmountCartItems",
    calculateTotalPrice().toFixed(2)
  );

  useEffect(() => {
    if (isDataLoaded) {
      // Get the selected country name from localStorage
      const selectedCountryName = (
        localStorage.getItem("setSelectedCountry") ||
        localStorage.getItem("country")
      )?.toLowerCase();

      console.log(
        "Selected Country Name from localStorage:",
        selectedCountryName
      );

      if (DeliveryCountryList) {
        console.log("DeliveryCountryList:", DeliveryCountryList);

        const foundCountry = DeliveryCountryList.find(
          (item) => item.Country.toLowerCase() === selectedCountryName
        );

        console.log("Found Country:", foundCountry);

        if (foundCountry) {
          setDeliveryCountry(foundCountry.CountryID); // Set the country ID in state
        }
      }
    }
  }, [isDataLoaded]); // Dependency on isDataLoaded

  setTimeout(() => {
    setIsDropdownLoaded(true);
  }, 1800);

  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="pt-2 pb-0">
            {/* <Breadcrumb listTag="div">
              <BreadcrumbItem
                href="/"
                tag="a"
                className="td-none"
                style={{ color: "#B1B1B1" }}
              >
                Home
              </BreadcrumbItem>
              <BreadcrumbItem
                href="#"
                tag="a"
                className="td-none"
                style={{ color: "#787878" }}
              >
                Payment and delivery
              </BreadcrumbItem>
            </Breadcrumb> */}

            <Breadcrumb
              separator={<HiChevronRight />}
              items={[
                {
                  title: "Home",
                  href: "/",
                },
                {
                  title: "Shopping Cart",
                  href: "/my-cart",
                },
                {
                  title: "Payment and delivery",
                },
              ]}
            />
          </div>
        </>
        <div className="mt-4">
          <div className="row content-inner">
            <div
              id="center_column"
              className="center_column col-xs-12 col-sm-12 col-sm-push-0"
            >
              <ul
                className="step clearfix"
                id="order_step"
                style={{ paddingLeft: 0 }}
              >
                {/* <li className="step_current first"> */}
                <li className="first">
                  <span>
                    <em>01.</em> Summary
                  </span>
                </li>
                <li className="step_current second">
                  <span>
                    <em>02.</em> Proceed to Checkout
                  </span>
                </li>
                <li className="step_todo third">
                  <span>
                    <em>03.</em> Order Complete
                  </span>
                </li>
              </ul>
              <div id="order-detail-content" className="">
                <div className="row">
                  <div className="col-xl-8 col-md-7 col-sm-12 order-2 order-xl-1 order-md-1">
                    {ShowPrevPayment && (
                      <>
                        <div className="mt-3">
                          <>
                            <h3 className="text-default mt-5 mb-3">
                              {" "}
                              Delivery country
                            </h3>
                            <div className="row">
                              <div className="col-7 ">
                                {/*<label>
                                  Country <RequiredField />
                                 </label> */}

                                <select
                                  className="form-control"
                                  name="Billing Country"
                                  onChange={(e) => {
                                    handleDeliveryCountry(e);
                                  }}
                                >
                                  {DeliveryCountryList?.map((item, index) => {
                                    // Get the selected country from localStorage, fallback to 'country' if 'setSelectedCountry' is not available
                                    const selectedCountry =
                                      localStorage.getItem(
                                        "setSelectedCountry"
                                      ) || localStorage.getItem("country");

                                    return (
                                      <option
                                        value={item.CountryID}
                                        key={index}
                                        selected={
                                          item.Country.toLowerCase() ===
                                          selectedCountry?.toLowerCase()
                                        }
                                      >
                                        {item.Country}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </>

                          {DeliveryCountry && (
                            <>
                              <h3 className="text-default mt-5 mb-3">
                                {" "}
                                Payment Method
                              </h3>
                              <div className="row">
                                <div className="col-7 ">
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input default-check-color"
                                        type="radio"
                                        id="inlineCheckbox34"
                                        name="inlineCheckbox34"
                                        defaultChecked={PaymentType === "card"}
                                        onChange={() => setPaymentType("card")}
                                      />
                                      <label
                                        className="form-check-label"
                                        for="inlineCheckbox3"
                                      >
                                        Credit/Debit Card
                                      </label>
                                    </div>
                                    {/* {(AllCod &&
                                parseInt(CartItems[0].ProductCountry) === 16 &&
                                parseInt(DeliveryCountry) === 16) ||
                              (parseInt(CartItems[0].ProductCountry) === 226 &&
                                parseInt(DeliveryCountry) === 226) ? ( */}
                                    {console.log("allLog", AllCod)}
                                    {console.log(
                                      "proifedaf",
                                      CartItems[0].ProductCountry
                                    )}
                                    {AllCod &&
                                      parseInt(CartItems[0].ProductCountry) ===
                                      16 &&
                                      parseInt(DeliveryCountry) === 16 ? (
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input default-check-color"
                                          type="radio"
                                          id="inlineCheckbox34"
                                          name="inlineCheckbox34"
                                          defaultChecked={PaymentType === "cod"}
                                          onChange={() => setPaymentType("cod")}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="inlineCheckbox3"
                                        >
                                          Cash on delivery
                                        </label>
                                      </div>
                                    ) : null}
                                    {/* ) : null} */}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="row mt-5">
                          <div className="col-12">
                            <div style={{}}>
                              {isDropdownLoaded ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <Link
                                      to="/my-cart"
                                      className="button-exclusive btn"
                                      title="Continue shopping"
                                    >
                                      <i
                                        className="fas fa-chevron-left"
                                        style={{ paddingRight: 10 }}
                                      />
                                      Back to cart
                                    </Link>
                                    <button
                                      className="btn btn-default "
                                      disabled={
                                        region !== "Bangladesh" &&
                                        !DeliveryCountry
                                      }
                                      onClick={() => {
                                        setContinueButton(!ContinueButton);
                                        window.scrollTo({
                                          top: 0,
                                          behavior: "smooth",
                                        });
                                        setShowPrevPayment(false);
                                      }}
                                    >
                                      Continue
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Spinner size="sm">Loading</Spinner>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {!ShowPrevPayment && (
                      <>
                        {parseInt(DeliveryCountry) === 16 &&
                          parseInt(CartItems[0].ProductCountry) === 16 && (
                            <BangladeshDelivery
                              BanglaBazarPickUp={BanglaBazarPickUp}
                              PickUpByUser={PickUpByUser}
                              AllowStorePickup={AllowStorePickup}
                              setAllowStorePickup={setAllowStorePickup}
                              setPaymentStates={setPaymentStates}
                              setPaymentCities={setPaymentCities}
                              CountrySelect={DeliveryCountry}
                              PaymentType={PaymentType}
                              setPaymentType={setPaymentType}
                              ContinueButton={ContinueButton}
                              setContinueButton={setContinueButton}
                              setShowPrevPayment={setShowPrevPayment}
                              ShowPrevPayment={ShowPrevPayment}
                              CartItems={CartItems}
                              setCartItems={setCartItems}
                              DeliveryBy={DeliveryBy}
                              setDeliveryBy={setDeliveryBy}
                              // TotalPrice={TotalPrice}
                              TotalPrice={subTotal}
                              setTotalPrice={setTotalPrice}
                              ShippingPrice={ShippingPrice}
                              setShippingPrice={setShippingPrice}
                              ProductCombinationItems={ProductCombinationItems}
                              setProductCombinationItems={
                                setProductCombinationItems
                              }
                              product_ids={product_ids}
                              productGlobalShipping={productGlobalShipping}
                              productShippingAvailable={
                                productShippingAvailable
                              }
                              productCity={productCity}
                              TaxValue={TaxValue}
                              setTaxValue={setTaxValue}
                              OverallCity={OverallCity}
                              PaymentClient={PaymentMethod}
                            />
                          )}

                        {parseInt(DeliveryCountry) !== 16 &&
                          parseInt(CartItems[0].ProductCountry) === 16 && (
                            <GlobalDeliveryDHL
                              BanglaBazarPickUp={BanglaBazarPickUp}
                              PickUpByUser={PickUpByUser}
                              AllowStorePickup={AllowStorePickup}
                              setAllowStorePickup={setAllowStorePickup}
                              setPaymentStates={setPaymentStates}
                              setPaymentCities={setPaymentCities}
                              CountrySelect={DeliveryCountry}
                              PaymentType={PaymentType}
                              setPaymentType={setPaymentType}
                              ContinueButton={ContinueButton}
                              setContinueButton={setContinueButton}
                              setShowPrevPayment={setShowPrevPayment}
                              ShowPrevPayment={ShowPrevPayment}
                              CartItems={CartItems}
                              setCartItems={setCartItems}
                              DeliveryBy={DeliveryBy}
                              setDeliveryBy={setDeliveryBy}
                              TotalPrice={TotalPrice}
                              setTotalPrice={setTotalPrice}
                              ShippingPrice={ShippingPrice}
                              setShippingPrice={setShippingPrice}
                              ProductCombinationItems={ProductCombinationItems}
                              setProductCombinationItems={
                                setProductCombinationItems
                              }
                              product_ids={product_ids}
                              productGlobalShipping={productGlobalShipping}
                              productShippingAvailable={
                                productShippingAvailable
                              }
                              productCity={productCity}
                              OverallCity={OverallCity}
                              PaymentClient={PaymentMethod}
                            />
                          )}

                        {parseInt(DeliveryCountry) === 226 &&
                          parseInt(CartItems[0].ProductCountry) === 226 && (
                            <UsaDelivery
                              BanglaBazarPickUp={BanglaBazarPickUp}
                              PickUpByUser={PickUpByUser}
                              AllowStorePickup={AllowStorePickup}
                              setAllowStorePickup={setAllowStorePickup}
                              setPaymentStates={setPaymentStates}
                              setPaymentCities={setPaymentCities}
                              CountrySelect={DeliveryCountry}
                              PaymentType={PaymentType}
                              setPaymentType={setPaymentType}
                              ContinueButton={ContinueButton}
                              setContinueButton={setContinueButton}
                              setShowPrevPayment={setShowPrevPayment}
                              ShowPrevPayment={ShowPrevPayment}
                              CartItems={CartItems}
                              setCartItems={setCartItems}
                              DeliveryBy={DeliveryBy}
                              setDeliveryBy={setDeliveryBy}
                              TotalPrice={TotalPrice}
                              setTotalPrice={setTotalPrice}
                              ShippingPrice={ShippingPrice}
                              setShippingPrice={setShippingPrice}
                              ProductCombinationItems={ProductCombinationItems}
                              setProductCombinationItems={
                                setProductCombinationItems
                              }
                              product_ids={product_ids}
                              productGlobalShipping={productGlobalShipping}
                              productShippingAvailable={
                                productShippingAvailable
                              }
                              productCity={productCity}
                              TaxValue={TaxValue}
                              setTaxValue={setTaxValue}
                              OverallCity={OverallCity}
                              PaymentClient={PaymentMethod}
                            />
                          )}

                        {parseInt(DeliveryCountry) !== 226 &&
                          parseInt(CartItems[0].ProductCountry) === 226 && (
                            <GlobalDeliveryXPS
                              BanglaBazarPickUp={BanglaBazarPickUp}
                              PickUpByUser={PickUpByUser}
                              AllowStorePickup={AllowStorePickup}
                              setAllowStorePickup={setAllowStorePickup}
                              setPaymentStates={setPaymentStates}
                              setPaymentCities={setPaymentCities}
                              CountrySelect={DeliveryCountry}
                              PaymentType={PaymentType}
                              setPaymentType={setPaymentType}
                              ContinueButton={ContinueButton}
                              setContinueButton={setContinueButton}
                              setShowPrevPayment={setShowPrevPayment}
                              ShowPrevPayment={ShowPrevPayment}
                              CartItems={CartItems}
                              setCartItems={setCartItems}
                              DeliveryBy={DeliveryBy}
                              setDeliveryBy={setDeliveryBy}
                              TotalPrice={TotalPrice}
                              setTotalPrice={setTotalPrice}
                              ShippingPrice={ShippingPrice}
                              setShippingPrice={setShippingPrice}
                              ProductCombinationItems={ProductCombinationItems}
                              setProductCombinationItems={
                                setProductCombinationItems
                              }
                              product_ids={product_ids}
                              productGlobalShipping={productGlobalShipping}
                              productShippingAvailable={
                                productShippingAvailable
                              }
                              productCity={productCity}
                              TaxValue={TaxValue}
                              setTaxValue={setTaxValue}
                              OverallCity={OverallCity}
                              PaymentClient={PaymentMethod}
                            />
                          )}

                        {/* {parseInt(DeliveryCountry) !== 16 &&
                          parseInt(DeliveryCountry) !== 226 &&
                          parseInt(CartItems[0].ProductCountry) === 226 && (
                            <GlobalUSAShipping />
                          )} */}
                      </>
                    )}
                  </div>
                  <div className="col-xl-4 col-md-5 col-sm-12 order-1 order-xl-2 order-md-2">
                    {/* <div className="order-summary-box">
                      <h5 className="p-2 mb-0 mt-1">
                        {" "}
                        <i className="fas fa-info-circle text-orange"></i> Note
                      </h5>
                      <div className="section-1 p-2">
                        <span className="text-orange">
                          Store pick up will only be available if all selected
                          products are from same country and selected delivery
                          city.{" "}
                        </span>
                      </div>
                    </div> */}
                    <div className="order-summary-box  mt-3">
                      {/* TOTAL CART */}
                      <h5 className="p-3 pb-2 mb-0">Order Summary</h5>
                      <h6 className="text-secondary p-3 mb-0 pb-2 ">
                        {/* TOTAL CART */}
                        Products ({CartItems.length})
                      </h6>
                      <div className="secondary-border"> </div>

                      {/* CART SHOW TOTAL */}
                      {CartItems.map((item, index) => (
                        <CartDetailCartItem
                          product={item}
                          combination={ProductCombinationItems[index]}
                        />
                      ))}

                      <div className="secondary-border"></div>
                      <div className="section-1">
                        <div className="d-flex justify-content-between summary-detail">
                          <div className="summary-attrib">Subtotal</div>

                          <div className="summary-attrib-val">
                            {CartItems.length > 0 && CartItems[0].Currency}{" "}
                            {/* {parseFloat(TotalPrice).toFixed(2)} */}
                            {parseFloat(subTotal).toFixed(2)}
                          </div>
                        </div>

                        {DeliveryBy === "pathao" ||
                          DeliveryBy === "VS" ||
                          DeliveryBy === "usps_intl" ||
                          DeliveryBy === "usps" ||
                          DeliveryBy === "dhl" ||
                          DeliveryBy === "dd" ? (
                          <div className="d-flex justify-content-between summary-detail">
                            <div className="summary-attrib">
                              Shipping Amount
                            </div>
                            <div className="summary-attrib-val">
                              {CartItems.length > 0 && CartItems[0].Currency}{" "}
                              {ShippingPrice && ShippingPrice.toFixed(2)}
                            </div>
                          </div>
                        ) : null}

                        {console.log(TaxValue, "TaxValue1")}
                        {/* {JSON.stringify(TaxValue && TaxValue.toFixed(2))} */}

                        {loadAgain && TaxValue ? (
                          <div className="d-flex justify-content-between summary-detail">
                            <div className="summary-attrib">Tax Rate </div>
                            <div className="summary-attrib-val">
                              {CartItems.length > 0 && CartItems[0].Currency}{" "}
                              {TaxValue && TaxValue.toFixed(2)}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="section-1">
                        <div className="d-flex justify-content-between summary-detail-total">
                          <div className="summary-attrib">Total</div>
                          <div className="summary-attrib-val">
                            {CartItems.length > 0 && CartItems[0].Currency}{" "}
                            {/* {parseFloat(
                        ShippingPrice + TotalPrice + (TaxValue ? TaxValue : 0)
                      ).toFixed(2)} */}
                            {parseFloat(
                              ShippingPrice +
                              subTotal +
                              (TaxValue ? TaxValue : 0)
                            ).toFixed(2)}
                            {localStorage.setItem(
                              "usaCaseFinalPrice",
                              parseFloat(
                                ShippingPrice +
                                subTotal +
                                (TaxValue ? TaxValue : 0)
                              ).toFixed(2)
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="p-2">
                  <button className="btn btn-lg btn-block btn-success w-100">
                    Proceed To Checkout
                  </button>
                </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="clear" />
              <div className="cart_navigation_extra">
                <div id="HOOK_SHOPPING_CART_EXTRA" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <NewsLetter /> */}
      <Footer />
    </>
  );
}
export default PaymentAndDelivery;
