//test
// export const reCAPTCHA_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
export const reCAPTCHA_SITE_KEY = "6LeAkOgUAAAAACcy3uY6N9H9SJMS27n3Zx2OOnYK";

// export const reCAPTCHA_SITE_KEY = "6LdprMQqAAAAAGcx0i1ml7khX5HY47nT-_jE8yn1";
// export const reCAPTCHA_SECRET_KEY = "6Ld0tNAcAAAAAK0JceDgUrbQ80_xml-QpzI5LUaY";


//fordeployment
// export const reCAPTCHA_SITE_KEY = "6LfVbtEcAAAAAONakP7JS8KApkmy-xTnGtfjPz5H";
////export const reCAPTCHA_SECRET_KEY = "6Ld0tNAcAAAAAK0JceDgUrbQ80_xml-QpzI5LUaY";

//test user email
//tjooqwsde@emltmp.com
